import ReactDOM from "react-dom/client";
import Interface from "./Interface.jsx";
import Experience from "./Experience.jsx";
import * as THREE from "three";
import { Canvas } from "@react-three/fiber";
import { useEffect, useState } from "react";
import "./style.scss";

const root = ReactDOM.createRoot(document.querySelector("#root"));

const SHOT_BY_POSITION = 5;

const App = () => {
  const [isStarted, setIsStarted] = useState(false);
  const [state, setState] = useState({
    points: 0,
    stepNmb: 0,
    stepInterface: 0,
    isEnded: false,
    isEntered: false,
    steps: [
      {
        position: { x: 0, z: 4 },
        shoots: [],
        name: "Top Key",
      },
      {
        position: { x: 4, z: 2 },
        shoots: [],
        name: "Right Corner",
      },
      {
        position: { x: 3.5, z: 3 },
        shoots: [],
        name: "Right Wing",
      },
      {
        position: { x: -3.5, z: 3 },
        shoots: [],
        name: "Left Wing",
      },
      {
        position: { x: -4, z: 3 },
        shoots: [],
        name: "Left Corner",
      },

      {
        position: { x: 6, z: 2 },
        shoots: [],
        name: "Right Corner",
      },
      {
        position: { x: 5, z: 4.5 },
        shoots: [],
        name: "Right Wing",
      },
      {
        position: { x: 0, z: 6 },
        shoots: [],
        name: "Top Key",
      },
      {
        position: { x: -5, z: 4.5 },
        shoots: [],
        name: "Left Wing",
      },
      {
        position: { x: -6, z: 2 },
        shoots: [],
        name: "Left Corner",
      },
    ],
  });

  const init = () => {
    setIsStarted(true);
  };

  useEffect(() => {
    if (state.stepNmb !== 0) {
      setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          stepInterface: state.stepNmb,
        }));
      }, 1000);
    }
  }, [state.stepNmb]);

  const shootaShot = () => {
    const _steps = state.steps;

    setState((prevState) => ({
      ...prevState,
      steps: _steps,
      points: prevState.points + (state.stepNmb > 4 ? 3 : 2),
    }));

    const bonusSucces =
      SHOT_BY_POSITION - _steps[state.stepInterface].shoots.length;

    for (let index = 0; index < bonusSucces; index++) {
      setTimeout(() => {
        _steps[state.stepInterface].shoots.push(true);

        setState((prevState) => ({
          ...prevState,
          steps: _steps,
          points: prevState.points + 1,
        }));
      }, index * 300);
    }

    if (state.stepNmb + 1 >= state.steps.length) {
      setState((prevState) => ({
        ...prevState,
        isEnded: true,
      }));
    } else {
      setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          stepNmb: prevState.stepNmb + 1,
        }));
      }, 300 * SHOT_BY_POSITION);
    }
  };

  const missAShot = () => {
    const _steps = state.steps;
    _steps[state.stepNmb].shoots.push(false);

    setState((prevState) => ({
      ...prevState,
      steps: _steps,
    }));
  };

  useEffect(() => {
    console.log(state.stepNmb, state.steps.length);
    if (state.stepNmb + 1 >= state.steps.length) {
      setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          isEnded: true,
        }));
      }, 1000);
    } else {
      if (
        state.steps[state.stepInterface].shoots.length >= 5 &&
        state.steps[state.stepInterface].shoots.includes(true) === false
      ) {
        setState((prevState) => ({
          ...prevState,
          stepNmb: prevState.stepNmb + 1,
        }));
      }
    }
  }, [state.steps[state.stepInterface].shoots.length]);

  return (
    <>
      <Interface init={init} state={state} setState={setState} />
      <Canvas
        shadows
        camera={{
          fov: 60,
          near: 0.1,
          far: 200,
          position: [
            state.steps[state.stepNmb].position.x + 2,
            2,
            state.steps[state.stepNmb].position.z + 3,
          ],
        }}
        onCreated={({ gl }) => {
          gl.shadowMap.enabled = true;
          gl.shadowMap.type = THREE.PCFSoftShadowMap;
        }}
      >
        {isStarted && (
          <Experience
            state={state}
            setState={setState}
            missAShot={missAShot}
            shootaShot={shootaShot}
          />
        )}
        {/* <OrbitControls
        // minAzimuthAngle={-Math.PI / 4}
        // maxAzimuthAngle={Math.PI / 4}
        // minPolarAngle={-Math.PI / 4}
        // maxPolarAngle={Math.PI / 2.2}
        /> */}
      </Canvas>
    </>
  );
};

root.render(<App />);

export { SHOT_BY_POSITION };
