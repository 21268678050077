import { Physics, useRapier } from "@react-three/rapier";
import useGame from "./stores/useGame.jsx";
import Lights from "./Lights.jsx";
import { Level } from "./Level.jsx";
import Player from "./Player.jsx";
import BasketHDRI from "./assets/hdri/088_hdrmaps_com_free_2K.exr";
import { Environment } from "@react-three/drei";
import { useEffect, useState } from "react";
import { EffectComposer, Vignette } from "@react-three/postprocessing";
import { useThree } from "@react-three/fiber";
import gsap from "gsap";

export default function Experience({ state, setState, shootaShot, missAShot }) {
  const blocksCount = useGame((state) => state.blocksCount);
  const blocksSeed = useGame((state) => state.blocksSeed);
  const { camera } = useThree();
  const [triggerReset, setTriggerReset] = useState(false);
  const [isLaunched, setIsLaunched] = useState(true);

  const resetBall = () => {
    setIsLaunched(false);
    setTimeout(() => setIsLaunched(true), 10);
  };

  const updateCameraPosition = () => {
    const newPosition = state.steps[state.stepNmb].position;

    gsap.to(camera.position, {
      z: newPosition.z + 3,
      x: newPosition.x + newPosition.x / 2.6,
      duration: 1,
    });
  };

  useEffect(() => {
    updateCameraPosition();
  }, [state.stepNmb]);

  return (
    <>
      <color args={["#bdedfc"]} attach="background" />

      <Physics gravity={[0, -14.82, 0]} debug={false}>
        <Lights />
        <Level
          shootaShot={shootaShot}
          setTriggerReset={setTriggerReset}
          state={state}
          setState={setState}
          count={blocksCount}
          seed={blocksSeed}
        />
        {isLaunched && (
          <Player
            missAShot={missAShot}
            triggerReset={triggerReset}
            setTriggerReset={setTriggerReset}
            state={state}
            setState={setState}
            resetBall={resetBall}
          />
        )}
        <Environment
          ground={{ height: 8, radius: 100, scale: 30 }}
          files={BasketHDRI}
          background
          blur={0}
        />
        <EffectComposer>
          <Vignette eskil={false} offset={0.0} darkness={1.1} />
        </EffectComposer>
      </Physics>
    </>
  );
}
