import * as THREE from "three";
import CoverPub from "./assets/cover.jpg";
import { useLoader } from "@react-three/fiber";

const PanneauPub = ({ x, z }) => {
  const texturePub = useLoader(THREE.TextureLoader, CoverPub);
  const boxGeometry = new THREE.BoxGeometry(1, 1, 1);

  const wallMaterial = new THREE.MeshStandardMaterial({
    color: "slategrey",
    // transparent: true,
    // opacity: 0,
  });

  const pubMap = new THREE.MeshStandardMaterial({
    map: texturePub,
  });

  return (
    <>
      <mesh
        position={[x, 4, z]}
        geometry={boxGeometry}
        material={pubMap}
        scale={[7, 4, 0]}
      />
      <mesh
        position={[x, 4, z - 0.1]}
        geometry={boxGeometry}
        material={wallMaterial}
        scale={[6.9, 3.95, 0]}
      />

      {/* Poteau */}
      <mesh
        position={[x, 1.5, z - 0.2]}
        geometry={boxGeometry}
        material={wallMaterial}
        scale={[0.3, 4, 0.3]}
      />
    </>
  );
};

export default PanneauPub;
