import gsap from "gsap";
import { useEffect } from "react";
import { useRef } from "react";
import { fadeInAppear, fadeOutDiseappear } from "../../tools/animations";
import "./index.scss";

const StartPage = ({ init }) => {
  const titleRef = useRef();
  const subtitleRef = useRef();
  const paragraphRef = useRef();
  const CTARef = useRef();
  const contentPageRef = useRef();

  useEffect(() => {
    fadeInAppear(titleRef.current, 0.3, 0.3);
    fadeInAppear(subtitleRef.current, 0.6, 0.3);
    fadeInAppear(paragraphRef.current, 0.9, 0.3, () => {
      init();
    });
    fadeInAppear(CTARef.current, 1.2, 0.3);
  }, []);

  const diseapear = () => {
    fadeOutDiseappear(titleRef.current, 0.3, 0.3);
    fadeOutDiseappear(subtitleRef.current, 0.6, 0.3);
    fadeOutDiseappear(paragraphRef.current, 0.9, 0.3);
    fadeOutDiseappear(CTARef.current, 1.2, 0.3);
    fadeOutDiseappear(contentPageRef.current, 1.5, 0.3);
    contentPageRef.current.style.pointerEvents = "none";
    document.querySelector(".interface").style.pointerEvents = "none";
  };

  return (
    <div ref={contentPageRef} className="start-page">
      <div className="start-page_inner">
        <h1 ref={titleRef}>Waïv</h1>
        <h2 ref={subtitleRef}>Shoot contest</h2>
        <p ref={paragraphRef}>
          Marque le plus de points possible et tente de gagner une box
          spéciale&nbsp;!
        </p>
        <button ref={CTARef} onClick={() => diseapear()}>
          Commencer
        </button>
      </div>
    </div>
  );
};

export default StartPage;
