import gsap from "gsap";

const fadeInAppear = (
  el,
  duration = 0.3,
  delay = 0.3,
  onComplete = () => {}
) => {
  gsap.fromTo(
    el,
    {
      opacity: 0,
      y: 30,
    },
    {
      opacity: 1,
      y: 0,
      delay: delay,
      duration: duration,
      onComplete: () => {
        onComplete();
      },
    }
  );
};

const fadeOutDiseappear = (
  el,
  duration = 0.3,
  delay = 0.3,
  onComplete = () => {}
) => {
  gsap.to(el, {
    opacity: 0,
    y: 30,
    delay: delay,
    duration: duration,
    onComplete: () => {
      onComplete();
    },
  });
};

export { fadeInAppear, fadeOutDiseappear };
