import { useRapier, RigidBody } from "@react-three/rapier";
import { applyProps, useFrame, useThree } from "@react-three/fiber";
import { DragControls, useGLTF, useKeyboardControls } from "@react-three/drei";
import { useState, useEffect, useRef, useLayoutEffect } from "react";
// import BasketBallGLTF from "./assets/ball/orange_basketball_ball/scene.gltf";
import * as THREE from "three";
import { MathUtils } from "three";
import useGame from "./stores/useGame.jsx";
import SupportBall from "./Support.jsx";

const Player = ({
  triggerReset,
  setTriggerReset,
  state,
  missAShot,
  setState,
}) => {
  const body = useRef();
  const basketBallonRef = useRef();
  const stateRef = useRef(state);
  stateRef.current = state;

  const [moveState, setMoveState] = useState({
    duration: { start: null, end: null },
    isStarted: false,
    positions: {
      start: { x: null, y: null },
      end: { x: null, y: null },
    },
  });
  const [smoothedCameraTarget] = useState(() => new THREE.Vector3());
  const { camera } = useThree();

  useEffect(() => {
    if (triggerReset) {
      setTimeout(() => {
        if (state.isEntered === false) {
          missAShot();
        }

        reset(stateRef.current);
        setTriggerReset(false);
      }, 500);
    }
  }, [triggerReset]);

  const reset = (_state = null) => {
    setState((prevState) => ({
      ...prevState,
      isEntered: false,
    }));

    let newPos = null;
    if (_state) {
      newPos = _state.steps[_state.stepNmb].position;
    } else {
      newPos = state.steps[state.stepNmb].position;
    }

    body.current.setTranslation({ x: newPos.x, y: 1, z: newPos.z });
    body.current.setLinvel({ x: 0, y: 0, z: 0 });
    body.current.setAngvel({ x: 0, y: 0, z: 0 });

    setMoveState({
      duration: { start: null, end: null },
      isStarted: false,
      positions: {
        start: { x: null, y: null },
        end: { x: null, y: null },
      },
    });
  };

  const initSlideMovement = (e) => {
    setMoveState({
      duration: { start: Date.now(), end: null },
      isStarted: true,
      positions: {
        start: {
          x: e.targetTouches[0].clientX,
          y: e.targetTouches[0].clientY,
        },
        end: { x: null, y: null },
      },
    });
  };

  const endSlideMovement = (e) => {
    setMoveState({
      duration: { start: moveState.duration.start, end: Date.now() },
      isStarted: true,
      positions: {
        start: {
          x: moveState.positions.start.x,
          y: moveState.positions.start.y,
        },
        end: {
          x: e.changedTouches[0].clientX,
          y: e.changedTouches[0].clientY,
        },
      },
    });
  };

  const calculShot = () => {
    let shot = {
      duration: moveState.duration.end - moveState.duration.start,
      transfert: {
        x:
          (moveState.positions.end.x - moveState.positions.start.x) /
          window.innerWidth,
        y:
          (moveState.positions.start.y - moveState.positions.end.y) /
          window.innerHeight,
      },
    };

    // body.current.applyImpulse({
    //   x: shot.transfert.x / 2,
    //   // x: -state.steps[state.stepNmb].position.x / 10 + shot.transfert.x / 2,
    //   y: shot.transfert.y * 3.5,
    //   z: -shot.transfert.y * 1,
    // });

    console.log(
      -state.steps[state.stepNmb].position.x,
      -state.steps[state.stepNmb].position.z
    );

    body.current.applyImpulse({
      x: -state.steps[state.stepNmb].position.x / 15,
      // x: -state.steps[state.stepNmb].position.x / 10 + shot.transfert.x / 2,
      y: shot.transfert.y * 2.4,
      z: -state.steps[state.stepNmb].position.z / 15,
    });

    const intervalCameraUp = setInterval(() => {
      camera.fov = camera.fov + 1;
      camera.updateProjectionMatrix();

      if (camera.fov >= 60) {
        clearInterval(intervalCameraUp);
        camera.fov = 60;
        camera.updateProjectionMatrix();
      }
    }, 10);
  };

  const moveBallStart = (e) => {
    const percent =
      ((moveState.positions.start.y - e.touches[0].clientY) /
        window.innerHeight) *
      100;

    document.querySelector(".strength").style.bottom =
      Math.max(0, Math.min(100, percent)) + "%";

    camera.fov = 60 + Math.max(0, Math.min(100, percent)) / 10;
    camera.updateProjectionMatrix();
  };

  useEffect(() => {
    const canvas = document.querySelector("canvas");

    canvas.addEventListener("touchstart", initSlideMovement);
    canvas.addEventListener("touchmove", moveBallStart);
    canvas.addEventListener("touchend", endSlideMovement);

    if (moveState.positions.start.x && moveState.positions.end.x) {
      calculShot();
    }

    return () => {
      canvas.removeEventListener("touchstart", initSlideMovement);
      canvas.removeEventListener("touchmove", moveBallStart);
      canvas.removeEventListener("touchend", endSlideMovement);
    };
  }, [moveState]);

  useFrame((state, delta) => {
    const bodyPosition = body.current.translation();
    const cameraTarget = new THREE.Vector3();
    cameraTarget.copy(new THREE.Vector3(0, 2.2, -1.8));
    smoothedCameraTarget.lerp(cameraTarget, 3 * delta);
    state.camera.lookAt(new THREE.Vector3(0, 2.2, -1.8));
    const bodyRotation = body.current.rotation();

    if (basketBallonRef.current) {
      basketBallonRef.current.position.set(
        bodyPosition.x,
        bodyPosition.y,
        bodyPosition.z
      );
      basketBallonRef.current.rotation.setFromQuaternion(
        new THREE.Quaternion(
          bodyRotation.x,
          bodyRotation.y,
          bodyRotation.z,
          bodyRotation.w
        )
      );
    }
  });

  const basketBall = useGLTF("/basketBall.glb");
  const panier = useGLTF("/models/panier/basketball_hoop.glb");

  useLayoutEffect(() => {
    panier.scene.traverse((o) => {
      if (o.isMesh) {
        applyProps(o, {
          castShadow: true,
          receiveShadow: true,
          "material-envMapIntensity": 0.6,
        });
      }
    });
  }, [panier.scene]);

  return (
    <>
      <group>
        <RigidBody
          ref={body}
          canSleep={false}
          colliders="ball"
          restitution={1.4}
          friction={1}
          linearDamping={0.1}
          angularDamping={0.1}
          position={[
            state.steps[state.stepNmb].position.x,
            1,
            state.steps[state.stepNmb].position.z,
          ]}
          mass={0.624}
        >
          <mesh castShadow receiveShadow>
            <icosahedronGeometry args={[0.28, 6]} />
            <meshStandardMaterial
              flatShading
              opacity={0}
              transparent={true}
              color="#ee6730"
            />
          </mesh>
        </RigidBody>

        <SupportBall
          position={
            new THREE.Vector3(
              state.steps[state.stepNmb].position.x,
              0.2,
              state.steps[state.stepNmb].position.z
            )
          }
        />
        <primitive
          ref={basketBallonRef}
          castShadow
          receiveShadow
          object={basketBall.scene}
          scale={0.3}
        />
        <primitive
          object={panier.scene}
          castShadow
          receiveShadow
          rotation={[0, -Math.PI / 2, 0]}
          scale={[1.5, 1.5, 1.5]}
          position={[0, 2.2, -1.8]}
        />
      </group>
    </>
  );
};

export default Player;
