import { SHOT_BY_POSITION } from ".";

const BasketPointIndication = ({ el, index, state }) => {
  if (
    state.steps[state.stepInterface]?.shoots[SHOT_BY_POSITION - 1 - index] !==
      undefined &&
    state.steps[state.stepInterface]?.shoots[SHOT_BY_POSITION - 1 - index] ===
      true
  ) {
    return (
      <div className={`try try-${SHOT_BY_POSITION - 1 - index} succes`}></div>
    );
  } else if (
    state.steps[state.stepInterface]?.shoots[SHOT_BY_POSITION - 1 - index] !==
      undefined &&
    state.steps[state.stepInterface]?.shoots[SHOT_BY_POSITION - 1 - index] ===
      false
  ) {
    return <div className={`try try-${index} miss`}></div>;
  } else {
    return <div className={`try try-${index}`}></div>;
  }
};

export default BasketPointIndication;
