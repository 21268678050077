import { RigidBody, useRapier } from "@react-three/rapier";
import { useEffect, useRef } from "react";
import * as THREE from "three";

const SupportBall = ({ position }) => {
  const boxGeometry = new THREE.BoxGeometry(1, 1, 1);
  const hitMaterial = new THREE.MeshStandardMaterial({
    color: "red",
    visible: true,
    opacity: 0,
    transparent: true,
  });
  const rigidBodyRef = useRef();

  useEffect(() => {
    if (rigidBodyRef.current) {
      // Access the Rapier rigid body directly to set the position
      rigidBodyRef.current.setTranslation(position, true);
    }
  }, [position]); // Re-run this effect if the `position` prop changes

  return (
    <RigidBody ref={rigidBodyRef} type="dynamic">
      <mesh
        geometry={boxGeometry}
        receiveShadow
        material={hitMaterial}
        scale={[1, 1, 1]}
      />
    </RigidBody>
  );
};

export default SupportBall;
