import React from "react";
import * as THREE from "three";

const Poteau = ({ wallMaterial, hitMaterial }) => {
  const boxGeometry = new THREE.BoxGeometry(1, 1, 1);

  return (
    <>
      {/* Panneau */}
      <mesh
        position={[0, 4.14, -0.72]}
        //   visible={false}
        geometry={boxGeometry}
        material={wallMaterial}
        scale={[2.5, 1.5, 0.05]}
        material-opacity={0}
        material-visible={false}
        castShadow
        receiveShadow
      />

      {/* Haut du poteau */}
      <mesh
        position={[0, 3.7, -2.05]}
        geometry={boxGeometry}
        //   visible={false}
        material={hitMaterial}
        scale={[0.3, 3, 0.3]}
        rotation={[Math.PI / 3.2, 0, 0]}
        castShadow
        receiveShadow
      />

      {/* Poteau */}
      <mesh
        position={[0, 1.5, -3.2]}
        geometry={boxGeometry}
        material={hitMaterial}
        scale={[0.3, 4, 0.3]}
        castShadow
        receiveShadow
      />
    </>
  );
};

export default Poteau;
