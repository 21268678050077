import LogoNBA from "./assets/1200px-NBA_Logo.png";
import "./style.scss";
import "./interface.scss";
import BasketPointIndication from "./BasketPointIndication";
import { useEffect, useRef } from "react";
import gsap from "gsap";
import StartPage from "./pages/StartPage";

export default function Interface({ state, setState, init }) {
  const endPageRef = useRef();

  useEffect(() => {
    if (state.isEnded === true) {
      gsap.to(endPageRef.current, {
        top: 0,
      });
    }
  }, [state.isEnded]);

  return (
    <div className="interface">
      <div ref={endPageRef} className="end-page">
        <p>Terminé</p>
        <p>Todo: créer la page</p>
        <p>{state.points}</p>
      </div>

      <StartPage init={init} />

      <div className="top-section">
        <div className="left-part_top-section">
          <img src={LogoNBA} alt="" />
          <div className="column_left-part_top-section">
            <h1>
              Waïv
              <br />
              contest
            </h1>
            <div className="points_container">
              <p>{state.points}</p>
            </div>
          </div>
        </div>

        <div className="right-part_top-section">
          <p className="section_title">
            {state.stepNmb < 4 ? "inner section" : "outer section"}
          </p>

          <div className="game-part">
            <div className="game_part-content">
              <div className="title_game-part">
                <p>{state.steps[state.stepInterface].name}</p>
              </div>
              <div className="trys">
                {[...Array(5).keys()].map((el, index) => {
                  return (
                    <BasketPointIndication
                      state={state}
                      el={el}
                      index={index}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <p>{state.points} points</p> */}

      <div className="launch-bar">
        <div className="inner"></div>
        <div className="strength"></div>
      </div>
    </div>
  );
}
