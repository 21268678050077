import { useEffect, useRef } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import { useHelper } from "@react-three/drei";
import * as THREE from "three";
import { DirectionalLightHelper } from "three";

export default function Lights() {
  const light = useRef();
  const { camera } = useThree();

  useEffect(() => {
    light.current.position.z = -10 + camera.position.z + 1 - 4;
    light.current.target.position.z = camera.position.z - 4;
    light.current.target.updateMatrixWorld();
  }, [camera.position]);

  useHelper(light.current, DirectionalLightHelper, "red");

  return (
    <>
      <directionalLight
        ref={light}
        castShadow
        position={[-6, 3, 5]}
        intensity={0.5}
        shadow-mapSize={[1024, 1024]}
        shadow-camera-near={0.01}
        shadow-camera-far={50}
        shadow-camera-top={50}
        shadow-camera-right={50}
        shadow-camera-bottom={-50}
        shadow-camera-left={-50}
      />

      <ambientLight color="white" intensity={0.8} />
    </>
  );
}
