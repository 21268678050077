import * as THREE from "three";
import { CuboidCollider, RigidBody, useRapier } from "@react-three/rapier";
import { useMemo, useState, useRef, useEffect } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import { Box, Float, Text, Torus, useGLTF } from "@react-three/drei";
import Poteau from "./Poteau";
import PanneauPub from "./PanneauPub";

const boxGeometry = new THREE.BoxGeometry(1, 1, 1);

const wallMaterial = new THREE.MeshStandardMaterial({
  color: "slategrey",
  // transparent: true,
  // opacity: 0,
});
const floorMaterial = new THREE.ShadowMaterial({
  //   color: "#222",
  //   visible: true,
  //   opacity: 0,
  //   transparent: true,
});
const hitMaterial = new THREE.MeshStandardMaterial({
  color: "red",
  visible: true,
  opacity: 0,
  transparent: true,
});

const testMaterial = new THREE.MeshStandardMaterial({
  color: "red",
});

function Bounds({ setTriggerReset, state, setState, reset, shootaShot }) {
  const torusMesh = useRef();
  const [torusRadius, setTorusRadius] = useState(1);
  const [torusThickness, setTorusThickness] = useState(0.2);
  const { world } = useRapier();
  const { camera } = useThree();

  return (
    <>
      <RigidBody
        colliders="trimesh"
        onCollisionEnter={() => {}}
        type="fixed"
        restitution={0.2}
        friction={0}
      >
        <Torus
          position={[0, 3.6, -0.0]}
          scale={[0.55, 0.55, 0.55]}
          args={[1, 0.05, 3, 20]}
          rotation={[Math.PI / 2, 0, 0]}
          radius={torusRadius}
          ref={torusMesh}
          material-color="darkred"
          material-metalness={0.7}
          material-roughness={0.6}
          castShadow
          receiveShadow
        />
        <Poteau wallMaterial={wallMaterial} hitMaterial={hitMaterial} />
      </RigidBody>
      <RigidBody
        colliders="trimesh"
        onCollisionExit={() => {
          setTriggerReset(true);
        }}
        type="fixed"
        restitution={0.2}
        friction={0}
      >
        <mesh
          position={[0, -0.4, 0]}
          geometry={boxGeometry}
          receiveShadow
          material={floorMaterial}
          scale={[40, 0.2, 100]}
        />
      </RigidBody>

      <PanneauPub x={-7} z={-25} />
      <PanneauPub x={8} z={-25} />

      <CuboidCollider
        args={[0.2, 0, 0.2]}
        position={[0, 3.4, 0]}
        sensor
        onIntersectionEnter={() => {
          setState((prevState) => ({
            ...prevState,
            isEntered: true,
          }));

          if (state.stepNmb + 1 > state.steps.length) {
            setTimeout(() => {
              setState((prevState) => ({
                ...prevState,
                isEnded: true,
              }));
            }, 1000);
          } else {
            const intervalCamera = setInterval(() => {
              camera.fov = camera.fov - 1;
              camera.updateProjectionMatrix();

              if (camera.fov <= 50) {
                clearInterval(intervalCamera);

                const intervalCameraUp = setInterval(() => {
                  camera.fov = camera.fov + 1;
                  camera.updateProjectionMatrix();

                  if (camera.fov >= 60) {
                    clearInterval(intervalCameraUp);
                    camera.fov = 60;
                    camera.updateProjectionMatrix();
                  }
                }, 3);
              }
            }, 3);

            shootaShot();

            setTimeout(() => {
              setTriggerReset(true);
            }, 500);
          }
        }}
      />
    </>
  );
}

export function Level({
  setTriggerReset,
  count = 5,
  types = [],
  seed = 0,
  state,
  setState,
  reset,
  shootaShot,
}) {
  const { world } = useRapier();

  // useEffect(() => {
  //   if (state.points !== 0) {
  //     console.log("oui");

  //     const updateGravity = (newGravity) => {
  //       world.gravity = newGravity;
  //     };

  //     updateGravity({ x: 0, y: 0, z: 0 });
  //   }
  // }, [state.stepNmb]);

  return (
    <>
      <Bounds
        shootaShot={shootaShot}
        setTriggerReset={setTriggerReset}
        reset={reset}
        state={state}
        setState={setState}
        length={count + 2}
      />
    </>
  );
}
